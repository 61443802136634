@import 'colors';

$color-bg-active: #f2f8fc;
$color-panther-light: #848484;

.main-wrapper {
  width: 100%;
  height: 100%;
}

.confirm-email-exist {
  height: calc(100% - 61px);
}

.reset-mat-modal {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.offline-mode {
  display: none;
  padding: 5px 15px;
  text-align: center;
  background: -webkit-linear-gradient(left, #cd163e 0, #f44336cf 100%);
  background: linear-gradient(to right, #cd163e 0, #f44336cf 100%);
  color: #fff;
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  &.open {
    display: block;
  }
}

.offline {
  .offline-mode {
    display: block;
  }
}

.update-version-message {
  animation: fadeIn 2s;
  display: flex;
  padding: 15px 0;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  position: sticky;
  bottom: 0;
  background: rgba(246, 86, 44, 0.9);
  color: #fff;
  z-index: 100;
  line-height: 1;
  .icon {
    margin-right: 5px;
  }
  .action {
    position: relative;
    margin-left: 15px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      height: 1px;
      width: 100%;
      border-bottom: 1px dashed;
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear;
    }
  }
}

// Unclickable elements
.mod-disabled {
  user-select: none;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.password-eye-icon {
  position: absolute;
  width: 12px;
  height: 31px;
  align-items: center;
  right: 12px;
  font-size: 12px;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.is-internet-explorer {
  background-color: $bloody-marry;
  color: $color-white;

  .warn-icon {
    font-size: 20px;
  }

  .btn-ie {
    color: $color-white;
    top: 24px !important;
  }
}

.new-version-alert {
  .learn-more-btn {
    background-color: $color-white;
    color: $color-Svenn-Black;
    border-color: $gray-border;
  }

  .learn-more-btn:hover {
    background-color: $color-white;
  }
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0;
  padding: 0;
  margin: 0;
}

.new-version-alert {
  position: relative;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;

  .message {
    text-align: center;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .learn-more {
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
    white-space: nowrap;
    margin-left: 15px;
    color: $color-black-tint;
  }

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.close-hubspot-chat {
  width: 64px;
  height: 64px;
  position: fixed;
  bottom: 14px;
  right: 14px;
  z-index: 999999;
  //border: 1px solid;
  border-radius: 50%;
  display: none;
}
