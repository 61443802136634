// colors for application

$question-icon-color: #7b7b7b;

// box shadow
$gray-shadow: #0000004d;

// background
$white: #f6f6f6;
$darker-white: #f8f8f8;
$clear-white: #fff;
$white-1: #fafafa;
$light-blue: #f6f9fa;
$light-brick: #362f40;
$tape-measure: #e9ecf4;
$color-active-background: #f2f8fc;
$green-field: #bde37c;
$dark-white: #fdfdfd;

// text
$text-base: #4c5e70;
$text-black: #000000;
$text-dark: #333;
$text-dark-2: #444;
$text-dark-3: #292724;
$text-dark-4: #43425d;
$text-base-hover: 2 e8d99 darken(#4c5e70, 5%);
$text-input-placeholder: #7b8da0;
$text-grey-2: #ececec;
$text-grey-3: #b5b5b5;

//links
$link-base: #6991ac;
$link-hover: #2e8d99;
$link-active: #a5d1f2;

//separator line
$separator: #3a5b71;

// button colors
$login-btn: #b7b7b7;
$signin-button-hover: #39c166;
$action-btn-hover: #4a4a4a;
$pipes-6: #e8eeff;

// green
$green-1: #75c06b;
$green-2: #37b348;
$green-3: #33aa44;
$green-4: #298f37;
$green-5: #268b34;
$green-6: #249533;
$green-7: #39c166;
$green-8: #98ddad;

// mint
$mint: #b0edb8;
$mint-2: #e6ffea;
$mint-3: #b5e1bc;
$mint-4: #b1d9b6;

// black
$middle-dark-black: #444952;
$light-black: #7b868a;

// blue
$blue-1: #003956;
$blue-2: #144b67;
$highlight-blue: #0064FA;

// grey
$gray-1: #eaecef;
$gray-2: #e5e8ee;
$gray-3: #9fa9ba;
$gray-4: #ddddde;
$gray-5: #bcbcbc;
$gray-6: #dfe2e5;
$gray-7: #f1f3f7;
$gray-8: #ced0da;
$gray-9: #979797;
$gray-10: #d9d9d9;
$gray-11: #9d9d9d;
$gray-12: #414141;
$gray-13: #f9f9f9;
$gray-d4d: #a79494;
$gray-border: #dededf;
$cadet-grey: #969fb7;
$color-heather: #c1c7d6;

$color-grey: #707070; // rgb(112,112,112)

$gray-background: #dbdbdb;

// red
$red-1: #ff0000;
$red-2: #dd171e;
$red-3: #f85258;
$red-4: #b80404;
$red-5: #ba1319;
$red-6: #d6474c;
$error-red: #e44e4e;
$red-brick: #aa4a44;

//border
$border-color: #f3f3f3;
$go-green: #84cf0a;
$btn-orange-border: #c14808;

//hover-menu
$hv-menu_bg-color: #094868;
$hv-menu_input-bg: #0f5477;
$hv-menu_input-border: #206a8e;

// dropdowns
$disabled-background: #b1b2b4;
$disabled-border: #f0f0f0;
$disabled-text: #c2c2c2;

// Brand Colors
// Our palette contains a wide range of color and contrast to guarantee optimal visual accessibility.

// Main Colors
$color-Svenn-Black: #101820; // rgb(16, 24, 32)
$color-Svenn-Sunset: #e35205; // rgb(227, 82, 5)
$color-Svenn-Sun: #ed9b33; // rgb(237, 155, 51)
$color-Svenn-Nightsky: #051c2c; // rgb(5, 28, 44)
$color-Svenn-Eveningsky: #003d4c; // rgb(0, 61, 76)
$color-Svenn-Air: #2f6f7a; // rgb(47, 111, 122)
$color-Svenn-Mountains: #53565a; // rgb(83, 86, 90)
$color-Svenn-Orange-Red: #f96529;

// Black and Greyscale
$color-saw: #f4f6fb;

// Main button color
$color-green-regular: #2d9a3c; // rgb(55, 179, 72)

// Main button hover and border
$color-green-dark: #b1d9b6; // rgb(45, 154, 60)

// Red

// Cancel button color
$color-red-regular: #dc1515; // rgb(220, 21, 21)

// Cancel button hover and border
$color-red-dark: #a1070e; // rgb(161, 7, 14)

// Cancel button disabled
$color-red-light: #d9b1b1; // rgb(217, 177, 177)

// Black and Greyscale
$color-saw: #f4f6fb;

// Text color
$color-black-tint: #4d4d4d; // rgb(77, 77, 77)

$bloody-marry: #d13c17;

$color-silver-fade: #f7f9fa;

$color-black-placeholder: #b5b5b5; // rgb(181, 181, 181)
$color-black-light: #848484; // rgb(132, 132, 132)

// Main border color
$color-black-border: #dededf; // rgb(222, 222, 223)
$color-black-greyscale1: #d5d5d5; // rgb(213, 213, 213)
$color-black-greyscale2: #eeeeee; // rgb(238, 238, 238)
$color-black-greyscale4: #fafafa; // rgb(250, 250, 250)
$color-black-greyscale5: #e0e0e1;
$color-black-greyscale6: #e8e8e8;
$color-green-field-child: #edf3e3;
$color-sweet-nail: #fff0e8;

// WHITE

$color-white: #ffffff; // rgb(255, 255, 255)
$color-white-bg: #f5f7f8; // rgb(245, 247, 248)

// Links and others

$color-info: #78a9d9; // rgb(120, 169, 217)
$color-info2: #aad7ff;
$color-warning: #ffb300; // rgb(255, 179, 0)
$color-warning2: #f3b840;
$color-warning3: #fff570;

$client-color: #354052;

// New colors v2

$b2-dark-brick: #241d2d;
$loader-color: #2f6f7a;
$scrollbar-color: #BABABA; //rgba(186, 186, 186, 1)

